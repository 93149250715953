/* eslint-disable no-console */

import {Modal, notification} from 'antd'

import store from 'services/store'
import {unauthorize} from 'services/store/profile/effects'

let unauthorizeModalVisible = false

export function createError(err, extra, type) {
  return {
    type: type ? `@@ERROR/${type}` : '@@ERROR',
    payload: err,
    error: true,
    ...extra
  }
}

export function dispatchError(...args) {
  store.dispatch(createError(...args))
}

function errorHandle(store) {
  return (next) => (action) => {
    const {
      type: actionType,
      payload = {},
      meta: {
        type = 'error',
        message,
        description,
        duration
      } = {},
      error
    } = action

    if (error && !/^@@redux-form/.test(actionType)) {
      const timestamp = Date.now()
      const state = store.getState()
      console.info('timestamp:', timestamp)
      console.info('   action:', action)
      console.info('    state:', state)
      console.error(payload)

      let config

      if (message || description) {
        config = {
          type,
          message,
          description,
          duration
        }
      }

      if (payload.isApiError) {
        const {
          status,
          data
        } = payload.response || {}

        if (status === 403) {
          if (!unauthorizeModalVisible) {
            unauthorizeModalVisible = true

            Modal.error({
              title: '会话已过期',
              content: '会话已过期，请重新登录',
              onOk() {
                store.dispatch(unauthorize())

                unauthorizeModalVisible = false
              }
            })
          }

          config = null
        } else {
          if (!config) {
            config = {
              type: 'error',
              duration: 3
            }
          }

          switch (status) {
            case 400: {
              config.message = config.message || '参数错误'
              config.description = config.description || '参数错误，请检查参数是否正确'
              break
            }
            case 404: {
              config.message = config.message || '资源不存在'
              config.description = config.description || '资源不存在，该资源可能已经被移除'
              break
            }
            case 500: {
              config.message = config.message || '服务器错误'
              config.description = config.description || '服务器遇到了未知错误，请稍后再试'
              break
            }
            default: {
              config.message = config.message || '未知错误'

              if (!config.description) {
                if (data) {
                  config.description = typeof data === 'string' ? data : JSON.stringify(data)
                } else {
                  config.description = payload.message
                }
              }
            }
          }
        }
      }

      config && notification.open(config)
    }

    next(action)
  }
}

export default errorHandle
