import {createReducer, fluxStandardActionHandleEnhancer} from 're-reducer'
import updateHelper from 'immutability-helper'

import {namespace, initialState} from './selectors'

const reducer = createReducer({
  namespace,
  initialState,
  handles: {
    update: fluxStandardActionHandleEnhancer((state, action) => {
      const {
        payload: {
          username,
          token
        }
      } = action

      const nextState = updateHelper(state, {
        $merge: {
          username,
          token,
          authorized: !!token,
          pending: false
        }
      })

      return nextState
    })
  }
})

const {
  actions: {
    update
  }
} = reducer

export default reducer
export {
  update
}
