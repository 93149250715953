import {decode} from 'jsonwebtoken'
import {SubmissionError} from 'redux-form'

import {authorize as authorizeRequest} from 'services/request'
import apiV1 from 'services/api/galaxy'
import tokenCache from 'services/cache/token'
import {update} from '.'
import {tokenSelector, authorizedSelector} from './selectors'

export function unauthorize() {
  return (dispatch) => {
    dispatch(update({
      username: null,
      token: null
    }))

    tokenCache.destroy()
  }
}

export function authorize(token, callback) {
  return (dispatch, getState) => {
    if (!token) {
      dispatch(unauthorize())

      return
    }

    const state = getState()
    const prevToken = tokenSelector(state)
    const authorized = authorizedSelector(state)

    if (token === prevToken && authorized) {
      return
    }

    return new Promise((resolve) => {
      const {
        username,
        exp
      } = decode(token)

      resolve({
        username,
        exp
      })
    })
    .then((data) => {
      const {
        username,
        exp
      } = data

      if (exp * 1e3 < Date.now()) {
        dispatch(unauthorize())

        return
      }

      const profile = {
        username,
        token
      }

      dispatch(update(profile))
      tokenCache.write(token)
      authorizeRequest(token)

      if (callback) {
        callback(undefined, profile)
      }
    })
    .catch((err) => {
      if (callback) {
        callback(err)
      }

      dispatch(update.error(err))
    })
  }
}

export function login(data, callback) {
  return (dispatch) => {
    const {
      username,
      password
    } = data

    apiV1
      .login({
        username,
        password
      })
      .then((data) => {
        const {
          token
        } = data

        dispatch(authorize(token, callback))
      })
      .catch((err) => {
        const {
          isApiError,
          status
        } = err

        if (callback) {
          const error = new SubmissionError(
            isApiError && status === 403
              ? {
                password: '用户名或密码错误'
              }
              : err.message
          )

          callback(error)
        }

        dispatch(update.error(err))
      })
  }
}
