import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'

import profile from './profile'
import entities from './entities'
import timeline from './timeline'

const reducer = combineReducers({
  form,
  profile,
  entities,
  timeline
})

export default reducer
