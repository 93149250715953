import {createReducer} from 're-reducer'
import updateHelper from 'immutability-helper'

import pluralize from 'services/utils/pluralize'
import {namespace, initialState} from './selectors'

function entityEnhancer(next) {
  return (state, action) => {
    const {
      meta: {
        type
      } = {}
    } = action

    if (!type) {
      return state
    }

    return next(state, action)
  }
}

const reducer = createReducer({
  namespace,
  initialState,
  handles: {
    patch: entityEnhancer((state, action) => {
      const {
        payload,
        meta: {
          type,
          idPropName = 'id'
        } = {}
      } = action

      const records = pluralize(payload)

      if (!records.length) {
        return state
      }

      const entityPatch = records.reduce((result, x) => {
        const {
          [idPropName]: id
        } = x

        if (id !== undefined) {
          result[id] = x
        }

        return result
      }, {})
      const nextState = updateHelper(state, {
        [type]: {
          $apply(entities) {
            if (!entities) {
              return entityPatch
            }

            const nextEntities = updateHelper(entities, {
              $merge: entityPatch
            })

            return nextEntities
          }
        }
      })

      return nextState
    }),
    update: entityEnhancer((state, action) => {
      const {
        payload,
        meta: {
          type,
          idPropName = 'id'
        } = {}
      } = action

      const records = pluralize(payload)

      if (!records.length) {
        return state
      }

      const patch = records.reduce((result, x) => {
        const {
          [idPropName]: id
        } = x

        if (id !== undefined) {
          result[id] = {
            $apply(entity) {
              if (!entity) {
                return x
              }

              const nextEntity = updateHelper(entity, {
                $merge: x
              })

              return nextEntity
            }
          }
        }

        return result
      }, {})
      const nextState = updateHelper(state, {
        [type]: {
          $apply(entities = {}) {
            const nextEntities = updateHelper(entities, patch)

            return nextEntities
          }
        }
      })

      return nextState
    })
  }
})

const {
  actions: {
    patch,
    update
  }
} = reducer

export default reducer
export {
  patch,
  update
}
