import {createReducer, fluxStandardActionHandleEnhancer} from 're-reducer'
import updateHelper from 'immutability-helper'

import {namespace, initialState} from './selectors'

const reducer = createReducer({
  namespace,
  initialState,
  handles: {
    update: fluxStandardActionHandleEnhancer((state, action) => {
      const {
        payload: results
      } = action

      const nextState = updateHelper(state, {
        $merge: {
          results,
          pending: false
        }
      })

      return nextState
    }),
    pushHistory: (state, action) => {
      const {
        payload: id
      } = action
      const {
        histories
      } = state

      const index = histories.indexOf(id)

      if (index > -1 && index < 10) {
        return state
      }

      const nextState = updateHelper(state, {
        histories: {
          ...index > 0 && {
            $splice: [
              [index, 1]
            ]
          },
          $unshift: [id]
        }
      })

      return nextState
    },
  }
})

const {
  actions: {
    update,
    pushHistory,
  }
} = reducer

export default reducer
export {
  update,
  pushHistory,
}
