import tokenCache from 'services/cache/token'

export const namespace = 'profile'
export const initialState = {
  username: null,
  token: tokenCache.read(),
  authorized: false,
  pending: true
}

export function usernameSelector(state) {
  return state[namespace].username
}
export function tokenSelector(state) {
  return state[namespace].token
}
export function authorizedSelector(state) {
  return state[namespace].authorized
}
export function pendingSelector(state) {
  return state[namespace].pending
}
