export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME

export const TOKEN_CACHE_KEY = process.env.REACT_APP_TOKEN_CACHE_KEY
export const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME
export const PRISM_API_ENDPOINT = process.env.REACT_APP_PRISM_API_ENDPOINT
export const REDMINE_API_ENDPOINT = process.env.REACT_APP_REDMINE_API_ENDPOINT
export const GALAXY_API_ENDPOINT = process.env.REACT_APP_GALAXY_API_ENDPOINT
export const HUKOU_AUTH_URL = process.env.REACT_APP_HUKOU_AUTH_URL
export const POLICY_ENDPOINT = process.env.REACT_APP_POLICY_ENDPOINT
export const QA_URL = process.env.REACT_APP_QA_URL

export const WEBSITE_TITLE_TEMPLATE = `%s - ${WEBSITE_NAME}`
export const DICE_CLIENT_ISSUE = 14
