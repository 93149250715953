import { Galaxy } from 'services/request'

class Api extends Galaxy {
  login(body) {
    return this
      .custom('login')
      .post(body)
  }

  searchDialogueList(params) {
    return this
      .custom('store_info')
      .get({
        params
      })
  }

  fetchTimeLine(params) {
    return this
      .custom('chat_record')
      .get({
        params
      })
  }
  fetchLabelInfo() {
    return this
      .custom('label_info')
      .get()
  }
  createIssue(params) {
    return this
      .custom('create_issue')
      .post(params)
  }
}

const apiV1 = new Api({
  entry: 'v1'
})

export default apiV1
