import historiesCache from 'services/cache/histories'

const histories = historiesCache.read() || {}

export const namespace = 'timeline'
export const initialState = {
  results: [],
  histories: histories.ids || [],
  pending: false
}

export function resultsSelector(state) {
  return state[namespace].results
}
export function historiesSelector(state) {
  return state[namespace].histories
}
export function pendingSelector(state) {
  return state[namespace].pending
}
